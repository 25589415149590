import React from 'react'
import { Group } from 'react-konva'
import { updatePositionScaled } from '../../shared/utils'
import DrawingArea from './DrawingArea'

import Shapes from '../Shapes/Shapes'

function getGroupAttrs(count, imageBoundRect) {
  const el = document.querySelector('.pdf-viewer')

  if (el) {
    return getGroupAttrsPDF(count)
  }

  return getGroupAttrsImg(imageBoundRect)
}

function getGroupAttrsImg(imageBoundRect) {
  const result = []

  const el = document.querySelector('.img-viewer')

  if (el) {
    // too fast using query
    const group = {
      x: imageBoundRect.x,
      y: imageBoundRect.y,
      width: imageBoundRect.width,
      height: imageBoundRect.height,
      name: `group-1`,
      id: `group-1`,
    }

    result.push(group)
  }

  return result
}

function getGroupAttrsPDF(count) {
  const result = []

  const el = document.querySelector('.pdf-viewer')

  if (el.hasChildNodes) {
    const elPages = el.querySelectorAll('.page')

    if (elPages.length > 0) {
      for (let index = 0; index < count; index++) {
        const rectPage = elPages[index].getBoundingClientRect()
        const group = {
          x: rectPage.x,
          y: rectPage.y,
          width: rectPage.width,
          height: rectPage.height,
          name: `group-${index + 1}`,
          id: `group-${index + 1}`,
        }

        result.push(group)
      }
    }
  }

  return result
}

function LayerGroup({
  imageBoundRect,
  activeDrawingArea = '',
  objects = [],
  count = 1,
  isMouseDrawAction = true,
}) {
  const groups = []
  const groupAttrs = getGroupAttrs(count, imageBoundRect)

  if (groupAttrs.length > 0) {
    for (let index = 0; index < count; index++) {
      const objectData = updatePositionScaled(objects[index], groupAttrs[index])

      const element = (
        <Group
          key={index}
          x={groupAttrs[index].x}
          y={groupAttrs[index].y}
          width={groupAttrs[index].width}
          height={groupAttrs[index].height}
          name={groupAttrs[index].name}
          id={groupAttrs[index].id}
        >
          <DrawingArea
            id={index + 1}
            width={groupAttrs[index].width}
            height={groupAttrs[index].height}
            isMouseDrawAction={isMouseDrawAction}
            activeDrawingArea={activeDrawingArea}
          />
          <Shapes objectData={objectData} scale={1} />
        </Group>
      )

      groups.push(element)
    }
  }

  return <>{groups}</>
}

export default React.memo(LayerGroup)
