import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { buttonPromptType, promptType } from '../../shared/promptType';

const initialState = {
  promptActionType: null,
  isPromptShow: false,
  promptTitle: '',
  promptContent: '',
  promptCallback: null,
  buttonPromptType: buttonPromptType.CONTINUECANCEL,
  promptType: promptType.PRIMARY
};

const promptShow = (state, action) => {
  let callback = null;

  if (action.callbackPrompt !== null) {
    callback = { callback: action.callbackPrompt };
  }

  return updateObject(state, {
    isPromptShow: true,
    promptTitle: action.titlePrompt,
    promptContent: action.contentPrompt,
    promptCallback: callback,
    buttonPromptType: action.buttonPromptType,
    promptType: action.promptType,
  });
};

const promptHide = (state) => {
  return updateObject(state, {
    isPromptShow: false,
    promptTitle: '',
    promptContent: '',
    promptCallback: null
  });
};

const promptActionOk = (state, action) => {
  if (state.promptCallback !== null) {
    state.promptCallback.callback();
  }

  return updateObject(state, {
    promptActionType: action.promptActionType,
    isPromptShow: false,
    promptTitle: '',
    promptContent: '',
    promptCallback: null,
  });
};

const promptActionCancel = (state, action) => {
  return updateObject(state, {
    promptActionType: action.promptActionType,
    isPromptShow: false,
    promptTitle: '',
    promptContent: '',
    promptCallback: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROMPT_SHOW:
      return promptShow(state, action);

    case actionTypes.PROMPT_HIDE:
      return promptHide(state);

    case actionTypes.PROMPT_ACTION_OK:
      return promptActionOk(state, action);

    case actionTypes.PROMPT_ACTION_CANCEL:
      return promptActionCancel(state, action);

    default:
      return state;
  }
};

export default reducer;