let activePlatform =
  sessionStorage && sessionStorage.getItem('activePlatform')
    ? sessionStorage.getItem('activePlatform')
    : localStorage && localStorage.getItem('activePlatform')
    ? localStorage.getItem('activePlatform')
    : 'ngine'
export const getActivePlatfrom = () => {
  return activePlatform
}

export const setActivePlatfrom = (platform) => {
  activePlatform = platform
  if (localStorage) {
    localStorage.setItem('activePlatform', platform)
  }
  if (sessionStorage) {
    sessionStorage.setItem('activePlatform', platform)
  }
}
