import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import validator from 'validator'
import update from 'immutability-helper'
import { Helmet } from 'react-helmet'

import './AuthAdmin.scss'
import LogoNgine from '../../Icons/LogoNgine'
import * as actions from '../../store/actions/index'
import LoginForm from './LoginForm/LoginForm'
import { LoginContext } from '../../context/loginContext'
import clientLogin from '../../shared/clientLogin'
import * as Router from '../../shared/router'
import { NavLink } from 'react-router-dom'
// import branding from '../../shared/branding';

export const AuthAdmin = ({
  onAuth,
  authRedirectPath,
  isAuthenticated,
  clientId,
}) => {
  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [username, setUsername] = useState(defaultValueInput)
  const [password, setPassword] = useState(defaultValueInput)

  const handleSubmitLogin = () => {
    let isValidField = true

    if (validator.isEmpty(username.value)) {
      setUsername(
        update(username, {
          isEmpty: { $set: validator.isEmpty(username.value) },
        })
      )

      isValidField = false
    }

    if (validator.isEmpty(password.value)) {
      setPassword(
        update(password, {
          isEmpty: { $set: validator.isEmpty(password.value) },
        })
      )

      isValidField = false
    }

    if (isValidField) {
      onAuth(username.value, password.value)
    }
  }

  let authRedirect = null
  if (isAuthenticated) {
    authRedirect = <Redirect to={authRedirectPath} />
  }

  if (authRedirect === null && clientId && clientId === clientLogin.CUSTOMER) {
    authRedirect = <Redirect to={Router.signin} />
  }

  const loginContextValue = {
    email: {
      value: username.value,
      onChange: (e) => {
        setUsername(
          update(username, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
          })
        )
      },
      isEmpty: username.isEmpty,
      isInvalid: username.isInvalid,
    },
    password: {
      value: password.value,
      onChange: (e) => {
        setPassword(
          update(password, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isLength(e.target.value, { min: 6, max: 20 }),
            },
          })
        )
      },
      isEmpty: password.isEmpty,
      isInvalid: password.isInvalid,
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitLogin()
    }
  }

  return (
    <div className="Auth-Admin-wrapper">
      <Helmet>
        <title>ngine - Login</title>
      </Helmet>
      <div className="content-welcome">
        <div className="content-text">
          <h1>Welcome to central kanban</h1>
          <p>Log in one place to access all platforms</p>
        </div>
      </div>
      <div className="content-signin">
        <div className="Auth-Admin">
          {authRedirect}

          <div className="form-signin">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <LogoNgine width={220} />
            </div>
            <br />
            <br />
            <br />
            <LoginContext.Provider value={loginContextValue}>
              <LoginForm isAdmin onKeyDown={handleKeyDown} />
            </LoginContext.Provider>
            <NavLink to={Router.forgotPassword} className="btn btn-link">
              Forgot Password?
            </NavLink>
            <br />
            <br />
            <button
              type="button"
              className="btn btn-lg btn-darkprimary btn-block"
              onClick={handleSubmitLogin}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    clientId: state.auth.clientId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password, true)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthAdmin)
