import React from 'react'

export const PdfContext = React.createContext({
  onChangeScale: null,
  pdfViewerState: null,
  onPdfViewerState: null,
  pdfViewerWrappRef: null,
  updatePdfViewerWrappRef: null,
  layerPdfScroll: null,
  updateLayerPdfScroll: null,
  totalPage: 0,
  updateTotalPage: null,
  baseMarginPage: 0,
  marginPage: 0,
  activePage: 0,
  updateActivePage: null,
})
