import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import validator from 'validator'
import update from 'immutability-helper'

import './ResetPassword.scss'
import InputField from '../../../components/Form/InputField/InputField'
import SvgLogoLogin from '../../../Icons/LogoLogin'
import branding from '../../../shared/branding'
import orbs1 from '../oval-red@2x.png'
import orbs2 from '../oval-blue@2x.png'
import SvgCheckSuccess from '../../../Icons/CheckSuccess'
import * as Router from '../../../shared/router'
import { ResetPasswordAPI } from '../../../api/User/ResetPassword'
import FormHandler from '../../../factory/FormHandler'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'
import Spinner from '../../../components/Loader/Spinner/Spinner'

const ResetPassword = ({ match }) => {
  const resetPasswordToken = match.params.resetPasswordToken

  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [reTypePassword, setReTypePassword] = useState(defaultValueInput)
  const [password, setPassword] = useState(defaultValueInput)
  const [isSendedPassword, setIsSendedPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSendEmail = () => {
    if (
      !validator.isEmpty(password.value) &&
      !validator.isEmpty(reTypePassword.value) &&
      password.value === reTypePassword.value
    ) {
      const data = new FormHandler()
      data.append('password', password.value)
      data.append('re_type_password', reTypePassword.value)

      setIsLoading(true)

      const onNext = (response) => {
        if (response.success) {
          setIsSendedPassword(true)
        } else {
          toastCenter.message(
            'Not Found',
            response.messages[0],
            toastMessageType.ERROR
          )
        }
      }
      const onComplete = () => {
        setIsLoading(false)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      const resetPasswordApi = new ResetPasswordAPI()
      resetPasswordApi.subscribe(
        data.all(),
        resetPasswordToken,
        onNext,
        onComplete,
        onError
      )
    } else {
      setPassword(
        update(password, {
          isEmpty: { $set: validator.isEmpty(password.value) },
          isInvalid: { $set: !validator.isEmpty(password.value) },
        })
      )
      setReTypePassword(
        update(reTypePassword, {
          isEmpty: { $set: validator.isEmpty(reTypePassword.value) },
          isInvalid: { $set: !validator.isEmpty(reTypePassword.value) },
        })
      )
    }
  }

  const onChangePassword = (e) => {
    setPassword(
      update(password, {
        value: { $set: e.target.value },
        isEmpty: { $set: validator.isEmpty(e.target.value) },
        isInvalid: { $set: validator.isEmpty(e.target.value) },
      })
    )
  }

  const onChangeReTypePassword = (e) => {
    setReTypePassword(
      update(reTypePassword, {
        value: { $set: e.target.value },
        isEmpty: { $set: validator.isEmpty(e.target.value) },
        isInvalid: {
          $set:
            validator.isEmpty(e.target.value) ||
            e.target.value !== password.value,
        },
      })
    )
  }

  return (
    <div className="reset-password-wrapper h-100">
      <Helmet>
        <title>{branding.NAME} - Reset Password</title>
      </Helmet>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container h-100 position-relative">
          <div className="logo">
            <NavLink to={Router.home}>
              <SvgLogoLogin />
            </NavLink>
          </div>

          <div className="orbs1">
            <img src={orbs1} alt="" />
          </div>

          <div className="orbs2">
            <img src={orbs2} alt="" />
          </div>

          <div
            className={`form-reset-password fade ${
              isSendedPassword ? 'hide invisible' : 'show visible'
            }`}
          >
            <h1>New Password</h1>
            <p>Enter a new password for your account.</p>
            <InputField
              label="New Password"
              placeholder=""
              type="password"
              name="new_password"
              id="new_password"
              isRequired
              isInvalid={password.isInvalid}
              isEmpty={password.isEmpty}
              onChange={onChangePassword}
              value={password.value}
            />
            <br />
            <InputField
              label="Re-Type New Password"
              placeholder=""
              type="password"
              name="re_type_new_password"
              id="re_type_new_password"
              isRequired
              isInvalid={reTypePassword.isInvalid}
              isEmpty={reTypePassword.isEmpty}
              onChange={onChangeReTypePassword}
              value={reTypePassword.value}
            />

            <div className="d-flex flex-row justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-send-password"
                onClick={handleSendEmail}
              >
                Reset Password
              </button>
            </div>
          </div>

          <div
            className={`success-submit fade ${
              isSendedPassword ? 'show visible' : 'hide invisible'
            }`}
          >
            <SvgCheckSuccess />
            <h1>Reset Password Success</h1>
            <p className="desc">Click the link bellow to login to dashboard.</p>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <NavLink
                to={Router.home}
                className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-send-password d-flex justify-content-center align-items-center"
              >
                Login
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
