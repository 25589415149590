import React, { useEffect, useState } from 'react'
import {
  getActivePlatfrom,
  setActivePlatfrom,
} from '../../../shared/activePlatform'
import SelectField from '../../Form/SelectField/SelectField'

function Platform() {
  const [platforms, setPlatforms] = useState([])
  const [selectedPlatform, setSelectedPlatform] = useState(null)

  const handleChangePlatform = (value) => {
    setSelectedPlatform(value)
    setActivePlatfrom(value.value)
    window.location.href = '/'
  }

  useEffect(() => {
    let currentPlatform = getActivePlatfrom()
    if (localStorage) {
      if (localStorage.getItem('platforms') && currentPlatform) {
        let storagePlatforms = JSON.parse(localStorage.getItem('platforms'))
        let activePlatform = storagePlatforms[currentPlatform]
        setSelectedPlatform({
          value: activePlatform.code,
          label: activePlatform.name,
        })

        let platformOptions = []
        Object.keys(storagePlatforms).forEach((code) => {
          let platform = storagePlatforms[code]
          platformOptions.push({
            value: code,
            label: platform.name,
          })
        })
        setPlatforms(platformOptions)
      }
    }
  }, [])

  return (
    <div>
      <SelectField
        label="Select Platform"
        placeholder="Select Platform"
        className="bg-white"
        selectedValue={selectedPlatform}
        options={platforms}
        onChange={handleChangePlatform}
      />
    </div>
  )
}

export default Platform
