import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import tippy from 'tippy.js'

import ProgressBar from '../../ProgressBar/ProgressBar'
import { isFloat } from '../../../shared/utility'

const ActiveOrder = ({ user = {}, isLoading = false, style = {} }) => {
  const [activeOrderUsed, setActiveOrderUsed] = useState(0)
  const [percentOfUsed, setPercentOfUsed] = useState(0)
  const [maximumActiveOrder, setMaximumActiveOrder] = useState(0)
  const [percentOfUsedTitle, setPercentOfUsedTitle] = useState('0%')
  const containerRef = useRef()

  useEffect(() => {
    tippy(containerRef.current, {
      content: 'Your active orders which can be processed at any one time.',
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
    })
  }, [])

  useEffect(() => {
    if (user !== null) {
      const currentActiveOrder = parseInt(
        user.membership.orders.currentActiveOrder
      )
      const maximumActiveOrder = parseInt(
        user.membership.orders.currentActiveOrder
      )
      const percent = maximumActiveOrder
        ? (currentActiveOrder /
            parseInt(user.membership.orders.maximumActiveOrder)) *
          100
        : 0
      setActiveOrderUsed(currentActiveOrder)
      setPercentOfUsed(percent)
      setPercentOfUsedTitle(
        `${isFloat(percent) ? parseFloat(percent).toFixed(1) : percent}%`
      )
    }
  }, [user])

  useEffect(() => {
    if (user !== null) {
      setMaximumActiveOrder(user.membership.orders.maximumActiveOrder)
    }
  }, [user])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  return (
    <div
      className="membership-status active-order"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Active Order</span>
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 ${
              parseInt(percentOfUsed) >= 90 ? 'text-danger' : 'text-light'
            }`}
          >
            <strong>{activeOrderUsed}</strong>
            <span>&nbsp;/&nbsp;</span>
            <strong>{maximumActiveOrder}</strong>
            <strong>&nbsp;Orders</strong>
            <span>&nbsp;({percentOfUsedTitle})</span>
          </div>
        </div>
      </div>
      <ProgressBar
        valuenow={activeOrderUsed}
        valuemin={0}
        valuemax={maximumActiveOrder}
      />
    </div>
  )
}

ActiveOrder.propTypes = {
  style: PropTypes.object,
}

export default ActiveOrder
