import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import { tokenCustomer, centralLoginEndpoint } from '../../shared/constant'

export class AuthAPI {
  constructor(username, password) {
    this.subscriptionCustomer = null
    this.subscriptionDesigndesk = null
    this.username = username
    this.password = encodeURIComponent(password)
  }

  subscribeToCustomer(next, complete, error) {
    const authorization = tokenCustomer

    const httpConfig = {
      url: '/c/token',
      method: 'post',
      data: `grant_type=password&username=${this.username}&password=${this.password}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${authorization}`,
      },
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig, false)
    })

    this.subscriptionCustomer = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribeToCustomer() {
    if (
      this.subscriptionCustomer !== undefined &&
      this.subscriptionCustomer !== null
    ) {
      this.subscriptionCustomer.unsubscribe()
    }
  }

  subscribeToDesigndesk(next, complete, error) {
    const httpConfig = {
      url: '/auth/login',
      method: 'post',
      data: `grant_type=password&username=${this.username}&password=${this.password}`,
      baseUrl: centralLoginEndpoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }
    
    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig, false)
    })

    this.subscriptionDesigndesk = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribeToDesigndesk() {
    if (
      this.subscriptionDesigndesk !== undefined &&
      this.subscriptionDesigndesk !== null
    ) {
      this.subscriptionDesigndesk.unsubscribe()
    }
  }
}
