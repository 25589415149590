import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Icon from 'react-feather'
import FeatherIcon from '../FeatherIcon/FeatherIcon'
import {
  toastMessageType,
  notificationMessageType,
} from '../../shared/toastCenter'
import { downloadProgressService } from '../../services/downloadProgressService/downloadProgressService'
import { uploadProgressService } from '../../services/uploadProgressService/uploadProgressService'

const ToastMessage = ({
  title,
  toastId = '',
  type = toastMessageType.SUCCESS,
  content = null,
  messages = [],
  uploadIndex = -1,
}) => {
  const [downloadDetail, setDownloadDetail] = useState(null)
  const [uploadDetail, setUploadDetail] = useState(null)
  const [theme, setTheme] = useState('')

  useEffect(() => {
    if (toastId && type === toastMessageType.DOWNLOAD) {
      const subscription = downloadProgressService.reload().subscribe((val) => {
        if (val.toastId === toastId) {
          setDownloadDetail(val)
        }
      })

      return () => {
        subscription.unsubscribe()
      }
    }

    return () => false
  }, [type, toastId])

  useEffect(() => {
    if (toastId && uploadIndex > -1 && type === toastMessageType.UPLOAD) {
      const subscription = uploadProgressService.reload().subscribe((val) => {
        if (val.idx === uploadIndex && val.toastId === toastId) {
          setUploadDetail(val)

          if (val.progress === 'fail') {
            setTheme('bg-danger')
          }
        }
      })

      return () => {
        subscription.unsubscribe()
      }
    }

    return () => false
  }, [type, uploadIndex, toastId])

  let msgIcon = null
  const propsIcon = {
    width: 42,
    height: 42,
  }

  switch (type) {
    case toastMessageType.SUCCESS:
      msgIcon = <Icon.Check {...propsIcon} />
      break
    case toastMessageType.WARNING:
      msgIcon = <Icon.AlertTriangle {...propsIcon} />
      break
    case toastMessageType.ERROR:
      msgIcon = <Icon.AlertOctagon {...propsIcon} />
      break
    case toastMessageType.LOADING:
      msgIcon = <FeatherIcon name="loader" />
      break
    case notificationMessageType.WARNING:
      msgIcon = <Icon.AlertTriangle {...propsIcon} />
      break
    case notificationMessageType.ERROR:
      msgIcon = <Icon.Info {...propsIcon} />
      break
    case notificationMessageType.INFO:
      msgIcon = <Icon.Info {...propsIcon} />
      break
    default:
      msgIcon = <Icon.Info {...propsIcon} />
  }

  let bodyContent = null

  switch (type) {
    case toastMessageType.DOWNLOAD:
      bodyContent = downloadDetail ? (
        <div className="d-flex flex-column">
          <div>
            <span className="font-weight-bold">{downloadDetail.fileName}</span>
          </div>
          <div>
            <span>{downloadDetail.fileSize}</span>
          </div>
          <div>
            <div className="progress" style={{ height: '8px' }}>
              <div
                className="progress-bar bg-primary"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${downloadDetail.percentCompleted}%` }}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {content ? (
            <span className="font-weight-bold">{content}</span>
          ) : (
            <span className="font-weight-bold">
              Start downloading your file. Please wait...
            </span>
          )}
        </div>
      )
      break
    case toastMessageType.UPLOAD:
      let contentStatus
      let titleUpload

      if (uploadDetail) {
        switch (uploadDetail.progress) {
          case 'uploading':
            titleUpload = 'Uploading'
            contentStatus = (
              <div className="progress" style={{ height: '8px' }}>
                <div
                  className="progress-bar bg-primary"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${uploadDetail.percentCompleted}%` }}
                ></div>
              </div>
            )
            break
          case 'fail':
            titleUpload = 'Failed'
            msgIcon = <Icon.AlertTriangle {...propsIcon} />
            contentStatus = (
              <div>
                <p>There is a Problem Uploading Your File.</p>
              </div>
            )
            break
          default:
            titleUpload = 'Success'
            contentStatus = (
              <div>
                <span>File has been successfully uploaded.</span>
              </div>
            )
        }
      }

      bodyContent = uploadDetail ? (
        <div className="d-flex flex-column">
          <div className="mb-2">
            <span className="font-weight-bold text-14">{titleUpload}</span>
          </div>
          <div>
            <span className="font-weight-bold">{uploadDetail.fileName}</span>
          </div>
          <div>
            <span>{uploadDetail.fileSize}</span>
          </div>
          <div>{contentStatus}</div>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="mb-2">
            <span className="font-weight-bold text-14">Uploading</span>
          </div>
          <div>
            <span className="font-weight-bold">
              Start uploading your file. Please wait...
            </span>
          </div>
        </div>
      )
      break

    default:
      bodyContent = (
        <>
          {content && content !== '' && (
            <div>
              <span>{content}</span>
            </div>
          )}

          {messages && messages.length > 0 && (
            <div style={{ marginLeft: '15px' }}>
              <ul className="m-0 p-0">
                {messages.map((msg, idx) => (
                  <li key={idx}>{msg}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )
      break
  }

  return (
    <div className={`d-flex flex-row p-3 ${theme}`}>
      <div className="d-flex flex-column mr-3">
        <span className={`text-light`}>{msgIcon}</span>
      </div>
      <div className="d-flex flex-column flex-grow-1">
        {title && (
          <div className="mb-2">
            <span className="font-weight-bold text-14">{title}</span>
          </div>
        )}

        {bodyContent}
      </div>
    </div>
  )
}

ToastMessage.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  toastId: PropTypes.string,
  content: PropTypes.string,
  messages: PropTypes.array,
  uploadIndex: PropTypes.number,
}

export default ToastMessage
