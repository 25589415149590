import React, { useContext } from 'react'
import * as Icon from 'react-feather'

import './ArchivedOrderFilter.scss'
import SelectField from '../Form/SelectField/SelectField'
import InputField from '../Form/InputField/InputField'
import DateRangePicker from '../Form/DateRangePicker/DateRangePicker'
import { ArchivedOrderFilterContext } from '../../context/archivedOrderFilterContext'
import ArchivedPresetFilterList from './ArchivedPresetFilterList'
import { Can } from '../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../shared/ability'
import { ThemeContext } from '../../context/themeContext'

const ArchivedOrderFilter = ({
  height,
  onEdit,
  onExpandFilter,
  onReset,
  isExpand,
}) => {
  const archivedOrderFilterContext = useContext(ArchivedOrderFilterContext)
  const themeContext = useContext(ThemeContext)

  const handleSave = () => {
    if (typeof archivedOrderFilterContext.savePreset === 'function') {
      archivedOrderFilterContext.savePreset()
    }
  }

  return (
    <div
      className="order-filter-wrapper"
      style={{ height: isExpand ? height : 'auto' }}
    >
      <div className="filter-header">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column">
              <span>
                <Icon.Filter className="feather" />
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="font-weight-bold">Archive Order Filter</span>
            </div>
            <div className="d-flex flex-column">
              <button
                type="button"
                className="btn btn-icon"
                onClick={onExpandFilter}
              >
                {isExpand ? (
                  <Icon.ChevronUp className="feather" />
                ) : (
                  <Icon.ChevronDown className="feather" />
                )}
              </button>
            </div>
          </div>
          <hr className="ml- mr-0 w-100 mb-0" />
        </div>
      </div>
      <div
        className="d-flex flex-column filter-body"
        style={{ height: 'calc(100% - 41px)' }}
      >
        <div className="d-flex flex-column preset-list">
          <ArchivedPresetFilterList onEdit={onEdit} onReset={onReset} />
        </div>
        {isExpand && (
          <div className="d-flex flex-column preset-form mt-3">
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_CLIENT}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Client"
                  placeholder="Select Client"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={archivedOrderFilterContext.client.value}
                  options={archivedOrderFilterContext.client.options}
                  onChange={archivedOrderFilterContext.client.onChange}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_CUSTOMER}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Customer"
                  placeholder="Select Customer"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={archivedOrderFilterContext.customer.value}
                  onFocus={archivedOrderFilterContext.customer.onFocus}
                  options={archivedOrderFilterContext.customer.options}
                  onChange={archivedOrderFilterContext.customer.onChange}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_CREATOR}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Order's Creator"
                  placeholder="Select Order's Creator"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={archivedOrderFilterContext.creator.value}
                  onFocus={archivedOrderFilterContext.creator.onFocus}
                  options={archivedOrderFilterContext.creator.options}
                  onChange={archivedOrderFilterContext.creator.onChange}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_MANAGER}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Order's Manager"
                  placeholder="Select Order's Manager"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={archivedOrderFilterContext.manager.value}
                  options={archivedOrderFilterContext.manager.options}
                  onChange={archivedOrderFilterContext.manager.onChange}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_OPERATOR}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Order's Assigned"
                  placeholder="Select Order's Assigned"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={archivedOrderFilterContext.orderAssigned.value}
                  options={archivedOrderFilterContext.orderAssigned.options}
                  onChange={archivedOrderFilterContext.orderAssigned.onChange}
                />
              </div>
            </Can>
            <div className="d-flex flex-column">
              <SelectField
                label="Order's Services Category"
                placeholder="Select Order's Services Category"
                className="bg-white"
                isMulti
                isClearable
                closeMenuOnSelect
                isScrollToSelected={false}
                selectedValue={archivedOrderFilterContext.serviceCategory.value}
                options={archivedOrderFilterContext.serviceCategory.options}
                onChange={archivedOrderFilterContext.serviceCategory.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <SelectField
                label="Order's Services Type"
                placeholder="Select Order's Services Type"
                className="bg-white"
                isMulti
                isClearable
                closeMenuOnSelect
                isScrollToSelected={false}
                selectedValue={archivedOrderFilterContext.serviceType.value}
                options={archivedOrderFilterContext.serviceType.options}
                onChange={archivedOrderFilterContext.serviceType.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <DateRangePicker
                formClassName="date-input"
                label="Order's Created Date"
                startDate={archivedOrderFilterContext.orderCreated.startDate}
                endDate={archivedOrderFilterContext.orderCreated.endDate}
                onChange={archivedOrderFilterContext.orderCreated.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <DateRangePicker
                formClassName="date-input"
                label="Order's Updated Date"
                startDate={
                  archivedOrderFilterContext.orderUpdatedDate.startDate
                }
                endDate={archivedOrderFilterContext.orderUpdatedDate.endDate}
                onChange={archivedOrderFilterContext.orderUpdatedDate.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <InputField
                label="Custom Search"
                placeholder="Type Search"
                value={archivedOrderFilterContext.customSearch.value}
                onChange={archivedOrderFilterContext.customSearch.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <hr className="ml- mr-0 w-100" />
            </div>
            <div className="d-flex flex-column">
              <InputField
                label="Create Preset Filter"
                placeholder="Filter Name"
                maxLength={30}
                isRequired
                isEmpty={archivedOrderFilterContext.presetName.isEmpty}
                value={archivedOrderFilterContext.presetName.value}
                onChange={archivedOrderFilterContext.presetName.onChange}
                onKeyDown={archivedOrderFilterContext.presetName.onKeyDown}
              />
            </div>
            <div className="d-flex flex-column">
              <button
                type="button"
                className="btn"
                onClick={handleSave}
                style={themeContext.primaryButton}
              >
                Save Preset Filter
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ArchivedOrderFilter
