import { Subscriber } from 'rxjs'
import axios from 'axios'

import { baseURLEndpoint } from '../shared/constant'
import clientLogin from '../shared/clientLogin'
import toastCenter from '../shared/toastCenter'
import { resetKanban } from '../shared/utility'
import { getActivePlatfrom } from '../shared/activePlatform'

export default class AxiosSubscriber extends Subscriber {
  constructor(observer, config, isWithPrefix = true, useRelogin = true) {
    super(observer)
    const {
      url,
      method,
      data,
      params,
      headers,
      onDownloadProgress,
      onUploadProgress,
    } = config
    let defaultHeaders = headers
    let defaultOnUploadProgress = onUploadProgress
    let defaultOnDownloadProgress = onDownloadProgress

    if (headers === undefined || headers === null) {
      defaultHeaders = { 'Content-Type': 'application/json' }
    }

    if (!onDownloadProgress) {
      defaultOnDownloadProgress = () => {}
    }

    if (!onUploadProgress) {
      defaultOnUploadProgress = () => {}
    }

    const CancelToken = axios.CancelToken
    this.source = CancelToken.source()

    // XHR complete pointer
    this.completed = false

    let baseUrl = baseURLEndpoint
    const clientId = localStorage.getItem('clientId')
    if (clientId) {
      const urlPrefix = clientId === clientLogin.CUSTOMER ? '/c' : '/d'
      baseUrl = isWithPrefix ? baseURLEndpoint + urlPrefix : baseURLEndpoint
    }

    let currentPlatform = getActivePlatfrom()
    if (localStorage && localStorage.getItem('platforms') && currentPlatform) {
      let storagePlatforms = JSON.parse(localStorage.getItem('platforms'))
      let activePlatform = storagePlatforms[currentPlatform]
      baseUrl = isWithPrefix
        ? `${activePlatform.api_endpoint}/d`
        : activePlatform.api_endpoint
    }

    if (config.baseUrl) {
      baseUrl = config.baseUrl
    }

    // make axios request on subscription
    const http = axios.create({
      baseURL: baseUrl,
      headers: defaultHeaders,
      cancelToken: this.source.token,
      onUploadProgress: defaultOnUploadProgress,
      onDownloadProgress: defaultOnDownloadProgress,
    })

    let configDelete = { ...params }

    if (data) {
      configDelete = { ...params, data: data }
    }

    let request
    switch (method) {
      case 'post':
        request = http.post(url, data, { params })
        break
      case 'put':
        request = http.put(url, data, { params })
        break
      case 'delete':
        request = http.delete(url, configDelete)
        break
      default:
        request = http.get(url, { params })
    }

    request
      .then((response) => {
        if (response.data.reauth && useRelogin) {
          observer.complete()
          toastCenter.messageServerErrorCustom(response.data)
          resetKanban()
          setTimeout(() => {
            window.location.href = process.env.PUBLIC_URL
          }, 3000)
        } else {
          observer.next(response.data)
          this.completed = true
          observer.complete()
        }
      })
      .catch((error) => {
        this.completed = true
        observer.error(error)
      })
  }

  unsubscribe() {
    super.unsubscribe()

    // cancel XHR
    if (this.completed === false) {
      this.source.cancel('Operation canceled by the user.')
      this.completed = true
    }
  }
}
