import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import { centralLoginEndpoint } from '../../shared/constant'

export class ResendEmailVerficationCode {
  constructor() {
    this.subscription = null
  }

  subscribe(next, complete, error) {
    const token = localStorage.getItem('token')

    if (token) {
      const httpConfig = {
        url: '/auth/resend-email-verification',
        method: 'post',
        data: `accessToken=${token}`,
        baseUrl: centralLoginEndpoint,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }

      let observable$ = new Observable((observer) => {
        return new AxiosSubscriber(observer, httpConfig)
      })

      this.subscription = observable$.subscribe({
        next: next,
        complete: complete,
        error: error,
      })
    }
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
