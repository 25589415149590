import React, { useState, useEffect } from 'react'
import Modal from '../Modal/Modal'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import { startTourGuideService } from '../../services/tourGuideService/restartTourGuideService'
import branding from '../../shared/branding'

function AfterEditProfileTourGuide() {
  const [autoRun, setAutoRun] = useState(false)

  const startTourGuide = () => {
    window.location.href = '/'
  }

  const skipTourGuide = () => {
    tourGuideAction('all', 'false', () => {
      userDetailService.emitReload(true)
      window.location.href = '/'
    })
  }

  useEffect(() => {
    const subscription = startTourGuideService.reload().subscribe((value) => {
      if (value) {
        setAutoRun(value)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <Modal
      show={autoRun}
      onHideModal={setAutoRun}
      modalSize="modal-sm"
      isForceShow
      useClose={false}
    >
      <div
        className="afterEeditProfileTour"
        style={{ minHeight: '100px', textAlign: 'center' }}
      >
        <div>
          <p>
            Thank you for updating your profile.
            <br />
            Click [Start Tour] to begin your {branding.NAME} Tour Guide or you
            can [Skip] for now.
            <br /> You can always restart your Tour Guide in the General
            Settings
          </p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-secondary mr-3"
            onClick={skipTourGuide}
          >
            Skip
          </button>
          <button
            type="button"
            className="btn btn-darkprimary"
            onClick={startTourGuide}
          >
            Start Tour
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AfterEditProfileTourGuide
