import React from 'react'

export const defaultVariables = {
  primary: '#60269E',
  secondary: '#6fcfeb',
  orange: '#f68e56',
  red: '#EB0045',
  white: '#ffffff',
}

export const setVariables = (platform, setter) => {
  switch (platform) {
    case 'ngine':
      setter({
        primary: '#60269E',
        secondary: '#6fcfeb',
        orange: '#f68e56',
        red: '#EB0045',
        white: '#ffffff',
      })
      break
    case 'designdesk':
      setter({
        primary: '#00aeef',
        secondary: '#00aeef',
        orange: '#f68e56',
        red: '#EB0045',
        white: '#ffffff',
      })
      break
    case 'awp':
      setter({
        primary: '#36465e',
        secondary: '#66c1bf',
        orange: '#f68e56',
        red: '#EB0045',
        white: '#ffffff',
      })
      break
    default:
      setter(defaultVariables)
      break
  }
}
export const ThemeContext = React.createContext({
  variables: defaultVariables,
  primaryColor: {
    color: defaultVariables.primary,
  },
  secondaryColor: {
    color: defaultVariables.secondary,
  },
  primaryBackground: {
    backgroundColor: defaultVariables.primary,
  },
  secondaryBackground: {
    backgroundColor: defaultVariables.secondary,
  },
  primaryButton: {
    color: defaultVariables.white,
    backgroundColor: defaultVariables.primary,
    borderColor: defaultVariables.primary,
  },
})
