import React, { useRef } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import { withLabel } from '../../../hoc/withLabel/withLabel';
import { withEditable } from '../../../hoc/withEditable/withEditable';
import theme from '../../../shared/theme';

const contentStyle = () => ({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'normal',
  color: theme.DARK
});

const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    minHeight: '34px'
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: '0 5px',
    color: theme.RED,
    ':hover': {
      color: theme.RED,
    }
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0 5px',
    color: '#c3c3c3',
    ':hover': {
      color: '#c3c3c3',
    }
  }),
  indicatorSeparator: _ => ({
    display: 'none'
  }),
  groupHeading: (styles) => {
    return {
      ...styles,
      fontSize: '11.5px',
      fontWeight: 'bold',
      color: theme.DARKBLUE
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? theme.BLUE
          : isFocused
            ? '#DEEBFF'
            : '#fff',
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? '#FFF'
          : isFocused
            ? theme.DARK
            : theme.DARK,
      cursor: isDisabled ? 'not-allowed' : 'pointer',

      ':active': {
        ...styles[':active'],
        color: data.color,
        backgroundColor: !isDisabled && '#fff',
      },
    };
  },
  input: styles => ({ ...styles, ...contentStyle() }),
  placeholder: styles => ({ ...styles, color: '#c3c3c3' }),
  singleValue: (styles) => ({ ...styles, ...contentStyle() }),
};

const indicatorStyle = {
  indicatorSeparator: styles => ({
    ...styles,
    display: 'block'
  }),
}

const SelectField = React.forwardRef(({
  isRequired,
  isMulti,
  onChange,
  options,
  selectedValue,
  placeholder = "Please choose",
  onFocus = null,
  isEmpty = false,
  isClearable = false,
  isDisabled = false,
  customStyle = {},
  className = null,
  closeMenuOnSelect = true,
  isScrollToSelected = true
}, ref) => {
  const focusedRef = useRef();

  const Option = (props) => {
    return (
      <div
        ref={(el) => {
          if (props.isSelected && focusedRef.current !== el) {
            focusedRef.current = el;
          }
          if (props.innerRef) {
            props.innerRef(el);
          }
        }}
      >
        <components.Option {...props} />
      </div>
    );
  }

  const handleMenuOpen = () => {
    setTimeout(() => {
      if (isScrollToSelected && focusedRef.current) {
        focusedRef.current.scrollIntoView();
      }
    }, 100);
  }

  let styles = { ...colourStyles, ...customStyle };

  if (isClearable) {
    styles = { ...styles, ...indicatorStyle };
  }

  return (
    <div className={`textbox-form-container ${className ? className : ''} ${isDisabled ? 'textbox-form-container-disabled' : ''}`}>
      <Select
        className="w-100 react-select"
        ref={ref}
        options={options}
        placeholder={placeholder}
        styles={styles}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={onChange}
        onFocus={onFocus}
        value={selectedValue}
        components={{ Option }}
        onMenuOpen={handleMenuOpen}
        isDisabled={isDisabled}
        isClearable={isClearable} />
      {isRequired && isEmpty && (
        <div className={`input-validation-msg ${isEmpty && 'show'}`}>
          <span className="font-weight-bold">Required!</span>
        </div>
      )}
    </div>
  );
});

SelectField.propTypes = {
  isRequired: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.any,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  isScrollToSelected: PropTypes.bool,
};

export default withEditable(withLabel(SelectField));