import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import { centralLoginEndpoint } from '../../shared/constant'

export class EmailVerificationAPI {
  constructor() {
    this.subscription = null
  }

  subscribe(verificationToken, next, complete, error) {
    const httpConfig = {
      url: '/auth/email-verification',
      method: 'post',
      data: `verificationToken=${verificationToken}`,
      baseUrl: centralLoginEndpoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig)
    })

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
