export const buttonPromptType = {
  CONTINUECANCEL: 'CONTINUECANCEL',
  YESNO: 'YESNO',
  OK: 'OK'
};

export const promptType = {
  PRIMARY: 'PRIMARY',
  WARNING: 'WARNING'
};
