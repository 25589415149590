import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'

import Spinner from '../../../components/Loader/Spinner/Spinner'
import './VerifyEmail.scss'
import SvgLogoLogin from '../../../Icons/LogoLogin'
import * as Router from '../../../shared/router'
import orbs1 from '../oval-red@2x.png'
import orbs2 from '../oval-blue@2x.png'
import branding from '../../../shared/branding'
import { EmailVerificationAPI } from '../../../api/User/EmailVerification'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'

function VerifyEmail({ match }) {
  const verificationToken = match.params.verificationToken
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const onNext = (response) => {
      if (response.success) {
        setIsLoading(false)
      } else {
        toastCenter.message(
          'Verify email error',
          "Can't verify email",
          toastMessageType.ERROR
        )
      }
    }
    const onComplete = () => {}
    const onError = () => {
      toastCenter.messageServerError()
    }

    const emailVerificationApi = new EmailVerificationAPI()
    emailVerificationApi.subscribe(
      verificationToken,
      onNext,
      onComplete,
      onError
    )
  }, [verificationToken])

  return (
    <div className="verify-email-wrapper h-100">
      <Helmet>
        <title>{branding.NAME} - Login</title>
      </Helmet>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container h-100 position-relative">
          <div className="logo">
            <NavLink to={Router.home}>
              <SvgLogoLogin />
            </NavLink>
          </div>

          <div className="orbs1">
            <img src={orbs1} alt="" />
          </div>

          <div className="orbs2">
            <img src={orbs2} alt="" />
          </div>
          <div className="form-verify-email fade show visible">
            <h1>Success verify email</h1>
            <p className="desc">Click the link bellow to login to dashboard.</p>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <NavLink
                to={Router.home}
                className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-send-email d-flex justify-content-center align-items-center"
              >
                Login
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VerifyEmail
