import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import tippy from 'tippy.js'

import MembershipButtonPopover from '../MembershipButtonPopover/MembershipButtonPopover'
import ProgressBar from '../../ProgressBar/ProgressBar'
import * as Router from '../../../shared/router'
import { Can } from '../../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../../shared/ability'
import { getCurrentView } from '../../../shared/utility'

const MembershipOrder = ({ user, isLoading, style, history, showGear }) => {
  const containerRef = useRef()

  useEffect(() => {
    tippy(containerRef.current, {
      content:
        'The total orders available to use in your current subscription.',
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
    })
  }, [])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  const used = parseInt(user.membership.orders.used)
  const space =
    user.membership.orders.quota === '&infin;'
      ? 0
      : parseInt(user.membership.orders.quota)
  const percentOfUsedTitle =
    user.membership.orders.quota === '&infin;'
      ? ''
      : `(${user.membership.orders.percentOfUsed})`
  const percentOfUsed =
    user.membership.orders.quota === '&infin;' ? 100 : (used / space) * 100

  const upgradeHandleClick = () => {
    history.push(Router.managePackage)
  }

  return (
    <div
      className="membership-status"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Orders</span>
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 d-flex flex-row align-items-center ${
              user.membership.orders.quota !== '&infin;' &&
              parseInt(percentOfUsed) >= 90
                ? 'text-danger'
                : 'text-light'
            }`}
          >
            <strong>{used}</strong>
            <span>&nbsp;/&nbsp;</span>
            {user.membership.orders.quota === '&infin;' ? (
              <span
                dangerouslySetInnerHTML={{ __html: '&infin;' }}
                className="text-18 font-weight-bold line-height-10"
              ></span>
            ) : (
              <strong>{space}</strong>
            )}
            <strong>&nbsp;Orders</strong>
            <span>&nbsp;{percentOfUsedTitle}</span>
          </div>

          {showGear &&
            ((user.isClientWhitelabel && getCurrentView(user) === 'client') ||
              user.isSubscriber) && (
              <Can
                I={abilityAction.READ}
                a={abilityComponent.NAV_MANAGE_MEMBERSHIP}
              >
                <MembershipButtonPopover
                  onClick={upgradeHandleClick}
                  title={'Manage Membership'}
                  content={
                    user && user.membership && user.membership.freeTrial
                      ? 'The page you’re trying to reach will be available once you have passed your free trial'
                      : "Upgrade your monthly membership package to increase your monthly order's quota."
                  }
                  btnText="Manage"
                  style={{ float: 'right' }}
                  isWarningFull={
                    user.membership.orders.quota !== '&infin;' &&
                    parseInt(percentOfUsed) >= 90
                  }
                  isDisabled={
                    user && user.membership && user.membership.freeTrial
                  }
                />
              </Can>
            )}
        </div>
      </div>
      <ProgressBar
        valuenow={used}
        valuemin={0}
        valuemax={space}
        isGradient={user.membership.orders.quota === '&infin;'}
      />
    </div>
  )
}

MembershipOrder.propTypes = {
  style: PropTypes.object,
}

MembershipOrder.defaultProps = {
  showGear: true,
}

export default withRouter(MembershipOrder)
