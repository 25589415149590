export default [
    {
        name: 'published',
        label: 'Publish as Order',
        status: 'Publish'
    },
    {
        name: 'in-progress',
        label: 'In Progress',
        status: 'In Progress'
    },
    {
        name: 'in-review',
        label: 'In Review',
        status: 'In Review'
    },
    {
        name: 'ready-to-go',
        label: 'Ready to go',
        status: 'Ready to go'
    },
    {
        name: 'on-hold',
        label: 'Hold Order',
        status: 'Hold from {{orderProgress}}'
    },
    {
        name: 'queue',
        label: 'Queue Order',
        status: 'Queued'
    },
    {
        name: 'freeze',
        label: 'Frozen Order',
        status: 'Frozen'
    },
    {
        name: 'cancelled',
        label: 'Cancel Order',
        status: 'Cancelled from {{orderProgress}}'
    },
    {
        name: 'draft',
        label: 'Revert as Draft',
        status: 'Draft'
    },
    {
        name: 'delete',
        label: 'Delete Order',
        status: 'Delete'
    },
    {
        name: 'duplicate',
        label: 'Duplicate Order',
        status: 'Duplicate'
    },
    {
        name: 'resume',
        label: 'Resume Order',
        status: 'Resume'
    },
    {
        name: 'restore',
        label: 'Restore Order',
        status: 'Restore'
    }
];