import React from 'react';
import PropTypes from 'prop-types';

import './Header.scss';
import NavBar from './NavBar/NavBar';

const Header = ({ isFullScreen, onToggle }) => {
  return (
    <div className="header-wrapper">
      <header className="header-toolbar">
        <NavBar isFullScreen={isFullScreen}
          onToggle={onToggle} />
      </header>
    </div>
  );
};

Header.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default Header;