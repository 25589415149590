import React, { useState, useEffect, useContext, useCallback } from 'react'
import update from 'immutability-helper'

import View from './View'
import {
  shapeClassname,
  toolboxActionHandler,
  positionCommentPlace,
  typeDisplayAnnotation,
} from '../../shared/constants'
import {
  convertToCanvasGroupPosition,
  getGroupContainer,
  createShapeObject,
  convertToScreenPosition,
  isDrawingArea,
} from '../../shared/utils'
import { PreviewContext } from '../../context/previewContext'
import { TooltipContext } from '../../context/tooltipContext'
import { ContextMenuContext } from '../../context/contextMenuContext'
import { ShapeContext } from '../../context/shapeContext'
import { PdfContext } from '../../context/pdfContext'

const CanvasView = () => {
  //#region state
  const [startPos, setStartPos] = useState(null)
  const [points, setPoints] = useState([])
  const [isPaint, setIsPaint] = useState(false)
  const [isObjectHasCreated, setIsObjectHasCreated] = useState(false)
  const [index, setIndex] = useState(0)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [startY, setStartY] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [drawObject, setDrawObject] = useState(null)
  const [objects, setObjects] = useState([])
  const [groupTarget, setGroupTarget] = useState(-1)
  const [oldTargetShape, setOldTargetShape] = useState(null)

  const shapeContext = useContext(ShapeContext)
  const previewContext = useContext(PreviewContext)
  const pdfContext = useContext(PdfContext)
  const tooltipContext = useContext(TooltipContext)
  const contextMenuContext = useContext(ContextMenuContext)

  const canvasObjects = shapeContext.canvasObjects
  const updateCanvasObjects = shapeContext.updateCanvasObjects
  const groupCount = shapeContext.groupCount
  const onHasUnsavedComment = tooltipContext.onHasUnsavedComment
  const { activeDrawingArea, updateActiveDrawingArea } = previewContext
  //#endregion

  //#region useEffect

  // split canvas object into each group position
  useEffect(() => {
    if (canvasObjects.length > 0) {
      const results = []

      for (let index = 0; index < groupCount; index++) {
        const shapes = canvasObjects.filter((x) => x.attrs.group === index)

        if (!results[index]) {
          results[index] = []
        }

        if (shapes.length > 0) {
          results[index] = [...shapes]
        }
      }

      setObjects(results)
    } else {
      setObjects([])
    }
  }, [canvasObjects, groupCount])
  //#endregion

  //#region function
  const createObject = useCallback(
    (e) => {
      if (groupTarget === -1) {
        // do nothing
        return
      }

      const groupAttrs = e.currentTarget.children[0].children[groupTarget].attrs
      const startPosGroupArea = convertToCanvasGroupPosition(
        startPos,
        groupAttrs
      )
      const movePos = e.currentTarget.getPointerPosition()
      const movePosGroupArea = convertToCanvasGroupPosition(movePos, groupAttrs)

      let pencilPoints = []

      // pencil only
      if (previewContext.toolboxComponentAction.type === shapeClassname.LINE) {
        const drawArea = {
          x: groupAttrs.x,
          y: groupAttrs.y,
          maxWidth: groupAttrs.x + groupAttrs.width,
          maxHeight: groupAttrs.y + groupAttrs.height,
        }
        let pencilPoint = {
          x: movePosGroupArea.x,
          y: movePosGroupArea.y,
        }

        // check area allowed drawing
        // x area
        if (movePos.x > drawArea.maxWidth) {
          pencilPoint.x = groupAttrs.width
        } else if (movePos.x < drawArea.x) {
          pencilPoint.x = 0
        }

        // check area allowed drawing
        // y area
        if (movePos.y > drawArea.maxHeight) {
          pencilPoint.y = groupAttrs.height
        } else if (movePos.y < drawArea.y) {
          pencilPoint.y = 0
        }

        pencilPoints = points.concat([pencilPoint.x, pencilPoint.y])
        setPoints(pencilPoints)
      }

      const obj = createShapeObject(
        previewContext.toolboxComponentAction,
        groupAttrs,
        startPosGroupArea,
        movePosGroupArea,
        pencilPoints,
        previewContext.toolboxComponentAction.color,
        {
          group: groupTarget,
          groupAttrs: groupAttrs,
          version: 0.2,
        }
      )

      if (obj !== null) {
        setIsObjectHasCreated(true)
        updateCanvasObjects((x) =>
          update(x, {
            $splice: [[index, 1, obj]],
          })
        )

        return obj
      }
    },
    [
      groupTarget,
      index,
      points,
      previewContext.toolboxComponentAction,
      startPos,
      updateCanvasObjects,
    ]
  )

  const createDotObject = useCallback(
    (e) => {
      if (groupTarget === -1) {
        // do nothing
        return
      }

      const groupAttrs = e.currentTarget.children[0].children[groupTarget].attrs
      const pos = convertToCanvasGroupPosition(
        e.currentTarget.getPointerPosition(),
        groupAttrs
      )

      const obj = createShapeObject(
        previewContext.toolboxComponentAction,
        groupAttrs,
        pos,
        null,
        [],
        previewContext.toolboxComponentAction.color,
        {
          group: groupTarget,
          groupAttrs: groupAttrs,
          version: 0.2,
        }
      )

      // removeUnsavedObject();

      setIsObjectHasCreated(true)
      updateCanvasObjects((x) =>
        update(x, {
          $push: [obj],
        })
      )

      return obj
    },
    [groupTarget, previewContext.toolboxComponentAction, updateCanvasObjects]
  )

  const getGroupAttrs = useCallback((e, name) => {
    const groups = e.currentTarget.children[0].children
    const idx = groups.findIndex((x) => x.attrs.name === name)
    if (idx > -1) {
      return groups[idx].attrs
    }

    return null
  }, [])

  const updateTooltipPlacement = useCallback(
    (pos) => {
      const height = window.innerHeight / 2

      if (pos.y <= height) {
        tooltipContext.onTooltipPlaceChanged(positionCommentPlace.BOTTOM)
      } else {
        tooltipContext.onTooltipPlaceChanged(positionCommentPlace.TOP)
      }
    },
    [tooltipContext]
  )

  const isHasUnsavedTopic = useCallback(() => {
    const idx = canvasObjects.findIndex((x) => x.isUnsaved)
    return idx > -1
  }, [canvasObjects])

  // show comment when click object
  const showCommentOnClickShape = useCallback(
    (e, targetShape) => {
      const className = targetShape.getClassName()
      let attrs = targetShape.attrs

      if (className === shapeClassname.LINE) {
        attrs = {
          x: targetShape.points()[0],
          y: targetShape.points()[1],
        }
      }

      let currentObject = targetShape
      const idx = canvasObjects.findIndex(
        (x) => x.attrs.id === targetShape.attrs.id
      )
      if (idx > -1) {
        currentObject = canvasObjects[idx]
      }

      const groupAttrs = getGroupAttrs(e, targetShape.attrs.groupAttrs.name)
      if (groupAttrs) {
        const shapePos = convertToScreenPosition(attrs, groupAttrs)

        // show comment by selected obj
        tooltipContext.onSelectedCommentTooltip(
          targetShape.attrs.id,
          shapePos,
          currentObject
        )

        updateTooltipPlacement(shapePos)
      }
    },
    [canvasObjects, getGroupAttrs, tooltipContext, updateTooltipPlacement]
  )

  const handleStageMouseDown = useCallback(
    (e) => {
      // hide context menu (right click)
      contextMenuContext.onIsShowcontextMenu(false)

      // only left mouse button
      if (e.evt.button !== 0) return

      /**
       * check area allowed drawing
       * return if click outside area drawing
       */
      if (!e.currentTarget.targetShape) return

      if (isHasUnsavedTopic()) return

      tooltipContext.onIsCloseTooltipEvent(false)
      const targetShape = e.currentTarget.targetShape

      setOldTargetShape(targetShape)
      const currentDrawingArea = targetShape.parent.attrs.name

      /**
       * return if not current active drawing area
       * this for set shadow on other disable area
       */
      if (
        activeDrawingArea !== '' &&
        activeDrawingArea !== currentDrawingArea
      ) {
        return
      }

      const mousePos = e.currentTarget.getPointerPosition()
      const groups = e.currentTarget.children[0].children
      const currentGroupTarget = getGroupContainer(mousePos, groups)

      // drag pdf scrol to
      if (
        previewContext.typeDisplay === typeDisplayAnnotation.PDF &&
        previewContext.toolboxComponentAction.handler ===
          toolboxActionHandler.MOUSE_CLICK
      ) {
        setStartX(e.evt.pageX - pdfContext.pdfViewerWrappRef.current.offsetLeft)
        setScrollLeft(pdfContext.pdfViewerWrappRef.current.scrollLeft)

        setStartY(e.evt.pageY - pdfContext.pdfViewerWrappRef.current.offsetTop)
        setScrollTop(pdfContext.pdfViewerWrappRef.current.scrollTop)
      }

      // all ready get group position
      const groupAttrs = targetShape.parent.attrs

      if (previewContext.toolboxComponentAction !== null) {
        if (targetShape && isDrawingArea(targetShape.attrs.name)) {
          // draging shape event
          const pos = mousePos
          setStartPos(pos)
          setIndex(canvasObjects.length)

          // pencil only
          if (
            previewContext.toolboxComponentAction.type === shapeClassname.LINE
          ) {
            const updatedPosByGroup = convertToCanvasGroupPosition(
              pos,
              groupAttrs
            )
            setPoints([updatedPosByGroup.x, updatedPosByGroup.y])
          }

          previewContext.onImageBoundRectChanged((v) =>
            update(v, {
              relativeX: { $set: e.evt.pageX - v.x },
              relativeY: { $set: e.evt.pageY - v.y },
            })
          )

          setIsPaint(true)
        }
      }

      setGroupTarget(currentGroupTarget)

      e.evt.stopPropagation()
      e.evt.preventDefault()
    },
    [
      contextMenuContext,
      isHasUnsavedTopic,
      tooltipContext,
      activeDrawingArea,
      previewContext,
      pdfContext.pdfViewerWrappRef,
      canvasObjects.length,
    ]
  )

  const handleStageMouseMove = useCallback(
    (e) => {
      // only left mouse button
      if (e.evt.button !== 0) return

      if (previewContext.toolboxComponentAction !== null && isPaint) {
        const movePos = e.currentTarget.getPointerPosition()

        if (
          previewContext.toolboxComponentAction.handler ===
          toolboxActionHandler.MOUSE_CLICK
        ) {
          // hide comment when dragging
          tooltipContext.onShowTooltip(false)

          if (previewContext.typeDisplay === typeDisplayAnnotation.IMAGE) {
            // detect has moving
            if (startPos.x !== movePos.x || startPos.y !== movePos.y) {
              // drag image handler
              previewContext.onImageBoundRectChanged((v) =>
                update(v, {
                  x: { $set: e.evt.pageX - v.relativeX },
                  y: { $set: e.evt.pageY - v.relativeY },
                })
              )
            }
          } else {
            // drag pdf and move container scroll
            const x =
              e.evt.pageX - pdfContext.pdfViewerWrappRef.current.offsetLeft
            const walkX = (x - startX) * 1 //scroll-fast
            const y =
              e.evt.pageY - pdfContext.pdfViewerWrappRef.current.offsetTop
            const walkY = (y - startY) * 1 //scroll-fast

            pdfContext.pdfViewerWrappRef.current.scrollLeft = scrollLeft - walkX
            pdfContext.pdfViewerWrappRef.current.scrollTop = scrollTop - walkY
          }

          if (!previewContext.isDragging) {
            previewContext.onIsDragging(true)
            previewContext.onTransitionProperty('none')
          }
        } else {
          setDrawObject(createObject(e))
        }
      }

      e.evt.stopPropagation()
      e.evt.preventDefault()
    },
    [
      createObject,
      isPaint,
      pdfContext.pdfViewerWrappRef,
      previewContext,
      scrollLeft,
      scrollTop,
      startPos,
      startX,
      startY,
      tooltipContext,
    ]
  )

  const handleStageMouseUp = useCallback(
    (e) => {
      // only left mouse button
      if (e.evt.button !== 0) return

      const targetShape = e.currentTarget.targetShape
      const currentPos = e.currentTarget.getPointerPosition()

      // only for dot object
      if (
        isPaint &&
        previewContext.toolboxComponentAction !== null &&
        previewContext.toolboxComponentAction.handler ===
          toolboxActionHandler.MOUSE_CLICK &&
        previewContext.toolboxComponentAction.type === shapeClassname.CIRCLE &&
        !previewContext.isDragging &&
        (!targetShape || isDrawingArea(targetShape.attrs.name))
      ) {
        const objDotCreated = createDotObject(e)
        updateTooltipPlacement(currentPos)

        tooltipContext.onSelectedCommentTooltip(
          objDotCreated.attrs.id,
          startPos,
          objDotCreated
        )

        // we always know, every new object created, is unsaved
        onHasUnsavedComment(true)

        const currentDrawingArea = e.currentTarget.targetShape.parent.attrs.name
        updateActiveDrawingArea(currentDrawingArea)
      }

      /**
       * show comment after shape created
       * after painting object done
       */
      if (isPaint && !previewContext.isDragging && isObjectHasCreated) {
        updateTooltipPlacement(currentPos)

        tooltipContext.onSelectedCommentTooltip(
          drawObject.attrs.id,
          startPos,
          drawObject
        )

        // we always know, every new object created, is unsaved
        onHasUnsavedComment(true)

        /**
         * update drawing area to active and set other with shadow
         * when after painting object done
         */
        const currentDrawingArea = oldTargetShape.parent.attrs.name
        updateActiveDrawingArea(currentDrawingArea)
      } else if (
        !previewContext.isDragging &&
        targetShape &&
        !isDrawingArea(targetShape.attrs.name)
      ) {
        // show comment when click object. all object
        showCommentOnClickShape(e, targetShape)
      }

      setIsPaint(false)
      previewContext.onIsDragging(false)
      previewContext.onTransitionProperty('all')
      setIsObjectHasCreated(false)
      setOldTargetShape(null)

      e.evt.stopPropagation()
      e.evt.preventDefault()
    },
    [
      createDotObject,
      drawObject,
      isObjectHasCreated,
      isPaint,
      oldTargetShape,
      onHasUnsavedComment,
      previewContext,
      showCommentOnClickShape,
      startPos,
      tooltipContext,
      updateActiveDrawingArea,
      updateTooltipPlacement,
    ]
  )

  const handleContextMenu = useCallback(
    (e) => {
      // show menu
      // set position menu
      // cancel drawing
      contextMenuContext.onPositionContextMenu({
        x: e.evt.pageX,
        y: e.evt.pageY,
      })
      contextMenuContext.onIsShowcontextMenu(true)

      e.evt.stopPropagation()
      e.evt.preventDefault()
    },
    [contextMenuContext]
  )
  //#endregion

  return (
    <View
      groupCount={groupCount}
      onMouseDown={handleStageMouseDown}
      onTouchStart={handleStageMouseDown}
      onMouseUp={handleStageMouseUp}
      onTouchEnd={handleStageMouseUp}
      onMouseMove={handleStageMouseMove}
      onTouchMove={handleStageMouseMove}
      onContextMenu={handleContextMenu}
      imageBoundRect={previewContext.imageBoundRect}
      layerPdfScroll={pdfContext.layerPdfScroll}
      typeDisplay={previewContext.typeDisplay}
      isMouseDrawAction={
        previewContext.toolboxComponentAction.type !== shapeClassname.MOUSE
      }
      activeDrawingArea={activeDrawingArea}
      objects={objects}
    />
  )
}

export default React.memo(CanvasView)
