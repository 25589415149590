import React, { useState, useEffect, useContext } from 'react'
import * as Icon from 'react-feather'
import { connect } from 'react-redux'
import tippy from 'tippy.js'

import './ArchivedPresetFilterList.scss'
import * as actions from '../../store/actions/index'
import { ArchivedOrderFilterPresetList } from '../../api/ArchivedOrderFilter/ArchivedOrderFilterPresetList'
import { ArchivedOrderFilterDeleteAPI } from '../../api/ArchivedOrderFilter/ArchivedOrderFilterDelete'
import toastCenter from '../../shared/toastCenter'
import FeatherIcon from '../FeatherIcon/FeatherIcon'
import { orderFilterService } from '../../services/orderFilterService/orderFilterService'
import { truncateText } from '../../shared/utility'
import { ThemeContext } from '../../context/themeContext'

const ArchivedPresetFilterList = ({
  onPromptShow,
  onEdit,
  onReset,
  onFilterOrder,
  archivedPresetFilter,
  user,
}) => {
  const [orderPresets, setOrderPresets] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(null)
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    const orderFilterPresetListApi = new ArchivedOrderFilterPresetList()
    const onNext = (response) => {
      if (response.success) {
        const results = response.data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
          return 0
        })
        setOrderPresets(results)
      }
    }
    const onComplete = () => {}
    const onError = () => {}

    orderFilterPresetListApi.subscribe(onNext, onComplete, onError)

    const subscription = orderFilterService.reload().subscribe((value) => {
      if (value) {
        orderFilterPresetListApi.subscribe(onNext, onComplete, onError)
      }
    })

    return () => {
      orderFilterPresetListApi.unsubscribe()
      subscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (user) {
      let selectedFilterLS = localStorage.getItem('selectedArchivedFilter')
      selectedFilterLS = selectedFilterLS ? JSON.parse(selectedFilterLS) : null
      if (
        selectedFilterLS &&
        selectedFilterLS.loggedId &&
        selectedFilterLS.loggedId === user.id
      ) {
        setSelectedFilter(selectedFilterLS)
      }
    }
    return () => {
      setSelectedFilter(null)
    }
  }, [user])

  useEffect(() => {
    if (selectedFilter) {
      onFilterOrder(selectedFilter)
    }
  }, [onFilterOrder, selectedFilter])

  useEffect(() => {
    tippy('.preset-name', {
      animation: 'shift-away',
      onTrigger(instance, event) {
        instance.setContent(instance.reference.dataset.tippyContent)
      },
    })

    tippy('.btn-preset-edit', {
      content: 'Edit',
      animation: 'shift-away',
    })

    tippy('.btn-preset-remove', {
      content: 'Remove',
      animation: 'shift-away',
    })

    tippy('.btn-reset-filter', {
      content: 'Reset Filter',
      animation: 'shift-away',
    })
  }, [orderPresets])

  const handleSelectPreset = (preset, user) => {
    if (
      !archivedPresetFilter.preset ||
      preset.id !== archivedPresetFilter.preset.id
    ) {
      const filter = {
        id: preset.id,
        loggedId: user.id,
        name: preset.name,
        serviceCategory: preset.serviceCategory
          ? preset.serviceCategory.split(',')
          : [],
        serviceType: preset.serviceType ? preset.serviceType.split(',') : [],
        operator: preset.operator ? preset.operator.split(',') : [],
        customer: preset.customer ? preset.customer.split(',') : [],
        creator: preset.creator ? preset.creator.split(',') : [],
        client: preset.client ? preset.client.split(',') : [],
        manager: preset.manager ? preset.manager.split(',') : [],
        customSearch: preset.customSearch,
        createdDate: {
          startDate: preset.createdDate.startDate,
          endDate: preset.createdDate.endDate,
        },
        updatedDate: {
          startDate: preset.updatedDate.startDate,
          endDate: preset.updatedDate.endDate,
        },
      }

      localStorage.setItem('selectedArchivedFilter', JSON.stringify(filter))
      onFilterOrder(filter)
    } else {
      localStorage.removeItem('selectedArchivedFilter')
      onFilterOrder(null)
    }
  }

  const handleEditPreset = (e, preset, user) => {
    e.preventDefault()
    e.stopPropagation()

    onEdit(preset, user)
  }

  const handleResetFilter = () => {
    if (typeof onFilterOrder === 'function') {
      onReset()
      onFilterOrder(null)
    }
  }

  const handleDeletePreset = (e, id) => {
    e.preventDefault()
    e.stopPropagation()

    onPromptShow(
      'Confirm',
      'Are you sure you want to delete this preset?',
      () => {
        const archivedOrderFilterDeleteApi = new ArchivedOrderFilterDeleteAPI()

        const onNext = (response) => {
          if (response.success) {
            let selectedFilterLS = localStorage.getItem(
              'selectedArchivedFilter'
            )
            selectedFilterLS = selectedFilterLS
              ? JSON.parse(selectedFilterLS)
              : null

            if (selectedFilterLS && selectedFilterLS.id === id) {
              localStorage.removeItem('selectedArchivedFilter')
            }

            toastCenter.message('Success', 'Filter preset deleted successfully')
            orderFilterService.emitReload(true)
            onReset()
            onFilterOrder(null)
          }
        }

        const onComplete = () => {}

        const onError = () => {
          toastCenter.messageServerError()
        }

        archivedOrderFilterDeleteApi.subscribe(id, onNext, onComplete, onError)
      }
    )
  }

  return (
    <div className="order-filter-preset-wrapper">
      <div className="d-flex">
        <span className="font-weight-bold">Saved Preset Filter</span>
        <button
          type="button"
          className="btn btn-icon btn-reset-filter ml-auto"
          onClick={handleResetFilter}
          style={themeContext.primaryColor}
        >
          <FeatherIcon name="refresh-cw" className="feather-14" />
        </button>
      </div>
      <hr />
      <div className="d-flex">
        {orderPresets.length > 0 ? (
          <ul className="list-unstyled w-100 m-0 order-preset-list">
            {orderPresets.map((preset, key) => (
              <li
                key={key}
                onClick={() => handleSelectPreset(preset, user)}
                className={
                  archivedPresetFilter.preset &&
                  archivedPresetFilter.preset.id === preset.id
                    ? 'active'
                    : ''
                }
                style={themeContext.primaryColor}
              >
                <div className="d-flex flex-row align-items-center">
                  <div className="d-flex align-items-center mr-2">
                    <div className="radio-input-container">
                      {archivedPresetFilter.preset &&
                        archivedPresetFilter.preset.id === preset.id && (
                          <span className="radio-input"></span>
                        )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className="preset-name"
                      data-tippy-content={preset.name}
                    >
                      {truncateText(preset.name, 20)}
                    </span>
                  </div>
                  <div className="d-flex flex-row ml-auto align-items-center">
                    <button
                      type="button"
                      className="btn btn-icon btn-preset-edit"
                      onClick={(e) => handleEditPreset(e, preset, user)}
                      style={themeContext.primaryColor}
                    >
                      <FeatherIcon name="edit-2" className="feather" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-icon text-danger btn-preset-remove"
                      onClick={(e) => handleDeletePreset(e, preset.id)}
                    >
                      <Icon.Trash2 className="feather" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div>
            <span className="text-grey font-italic">No presets defined</span>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ user, archivedPresetFilter }) => {
  return { archivedPresetFilter, user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPromptShow: (title, content, callback) =>
      dispatch(actions.promptShow(title, content, callback)),
    onFilterOrder: (preset) => dispatch(actions.archivedFilterOrder(preset)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedPresetFilterList)
