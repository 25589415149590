import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import tippy from 'tippy.js'

// import * as Icon from 'react-feather';

import ProgressBar from '../../ProgressBar/ProgressBar'
// import * as Router from '../../../shared/router';
// import { Can } from '../../../context/abilityContext';
// import { abilityAction, abilityComponent } from '../../../shared/ability';

const MembershipAllocatedHours = ({ user, isLoading, style, history }) => {
  const containerRef = useRef()

  useEffect(() => {
    tippy(containerRef.current, {
      content:
        'Your allocated hours per month minus the time used against your active orders.',
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
    })
  }, [])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  const used = parseFloat(
    user.membership.allocatedHour.used !== undefined
      ? user.membership.allocatedHour.used
      : 0
  )
  const space = parseFloat(
    user.membership.allocatedHour.quota !== undefined
      ? user.membership.allocatedHour.quota
      : 0
  )
  const percentOfUsed = (used / space) * 100
  const percentOfUsedTitle = user.membership.allocatedHour.percentOfUsed

  // const isWarningFull = parseInt(percentOfUsed) >= 90;

  // const handleNavAllocatedHours = () => {
  //   history.push(Router.manageAllocatedHours);
  // };

  return (
    <div
      className="membership-status"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Allocated Hours</span>
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 ${
              parseInt(percentOfUsed) >= 90 ? 'text-danger' : 'text-light'
            }`}
          >
            <strong>{user && user.membership.allocatedHour.used}</strong>
            <span>&nbsp;/&nbsp;</span>
            <strong>{user && user.membership.allocatedHour.quota}</strong>
            <strong>&nbsp;Hours</strong>
            <span>&nbsp;({percentOfUsedTitle})</span>
          </div>

          {/* <Can I={abilityAction.READ} a={abilityComponent.NAV_MANAGE_ALLOCATED_HOURS}>
            <button type="button"
              className={isWarningFull
                ? "btn btn-icon text-danger btn-plus-tooltip d-flex"
                : "btn btn-icon text-primary btn-plus-tooltip d-flex"}
              onClick={handleNavAllocatedHours}
              style={{ marginLeft: '5px' }}>
              <Icon.Settings className="feather" />
            </button>
          </Can> */}
        </div>
      </div>
      <ProgressBar valuenow={used} valuemin={0} valuemax={space} />
    </div>
  )
}

MembershipAllocatedHours.propTypes = {
  style: PropTypes.object,
}

export default withRouter(MembershipAllocatedHours)
