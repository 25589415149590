import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import * as Icon from 'react-feather'

import './MembershipButtonPopover.scss'

const duration = 300

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  visibility: 'hidden',
}

const transitionStyles = {
  entered: { opacity: 1, visibility: 'visible' },
  exited: { opacity: 0, visibility: 'hidden' },
}

const MembershipButtonPopover = ({
  title,
  content,
  onClick,
  style,
  isWarningFull,
  btnText,
  isDisabled = false,
}) => {
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const handleTooltip = () => {
    setIsShowTooltip(!isShowTooltip)
  }

  const wrapperRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setIsShowTooltip(false)
    }
  }

  const handleActionButton = (e) => {
    setIsShowTooltip(false)
    onClick(e)
  }

  return (
    <div
      className="membership-button-popover-container d-flex align-items-center"
      ref={wrapperRef}
      style={style}
      x-placement="top"
    >
      <button
        type="button"
        className={
          isWarningFull
            ? 'btn btn-icon text-danger btn-plus-tooltip d-flex'
            : 'btn btn-icon text-primary btn-plus-tooltip d-flex'
        }
        onClick={handleTooltip}
      >
        <Icon.Settings className="feather" />
      </button>
      <Transition in={isShowTooltip} timeout={0} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            className="text-center popover popover-upgrade fade show bs-popover-top"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className="arrow" style={{ right: '8px' }}></div>
            <p>
              <span className="font-weight-bold text-14">{title}</span>
            </p>
            {isDisabled ? (
              <p style={{ color: '#f68e56' }}>{content}</p>
            ) : (
              <p>{content}</p>
            )}

            {!isDisabled && (
              <button
                type="button"
                onClick={handleActionButton}
                className="btn btn-darkprimary"
                disabled={isDisabled}
              >
                {btnText}
              </button>
            )}
          </div>
        )}
      </Transition>
    </div>
  )
}

MembershipButtonPopover.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isWarningFull: PropTypes.bool.isRequired,
  style: PropTypes.object,
  btnText: PropTypes.string.isRequired,
}

export default MembershipButtonPopover
