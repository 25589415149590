import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import { centralLoginEndpoint } from '../../shared/constant'

export class ForgotPasswordAPI {
  constructor() {
    this.subscription = null
  }

  subscribe(data, next, complete, error) {
    const httpConfig = {
      url: '/auth/forgot-password',
      method: 'post',
      data: data,
      baseUrl: centralLoginEndpoint,
      headers: {
        accept: 'application/json',
        'Content-Type': `application/json`,
      },
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig, false)
    })

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
